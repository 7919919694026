@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #ffffff;
  overscroll-behavior-y: none;
  position: fixed;
  overflow: hidden;
}

@layer components {
  .outsideDiv {
    @apply mt-6 flex w-full flex-col items-center justify-center;
  }
  .inputDiv {
    @apply mt-2 flex w-full flex-row items-center justify-center;
  }
  .label {
    @apply mr-auto text-sm font-medium text-slate-700;
  }
  .input {
    @apply h-10 w-full rounded border border-slate-200 bg-slate-50 pl-1 indent-2 ring-green-500;
  }

  .textAreaLabel {
    @apply mr-auto mt-6 text-sm font-medium text-slate-700;
  }

  .textArea {
    @apply h-[80px] border border-slate-200 bg-slate-50 p-2 indent-2 text-sm placeholder:font-normal focus:outline-none;
  }

  /* radio buttons */
}
